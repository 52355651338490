import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Tasker - Add a Task to deactivate Motion Detection",
  "path": "/Advanced_User/Tasker/Motion_Detection_Deactivate_Add_Task/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "Use a macro tool to automate functions of your camera",
  "image": "../AU_SearchThumb_Tasker.png",
  "social": "/images/Search/AU_SearchThumb_Tasker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Tasker_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Tasker - Add a Task to deactivate Motion Detection' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use a macro tool to automate functions of your camera' image='/images/Search/AU_SearchThumb_Tasker.png' twitter='/images/Search/AU_SearchThumb_Tasker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Tasker/' locationFR='/fr/Advanced_User/Tasker/' crumbLabel="Tasker" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "tasker---add-a-task-to-deactivate-motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#tasker---add-a-task-to-deactivate-motion-detection",
        "aria-label": "tasker   add a task to deactivate motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tasker - Add a Task to deactivate Motion Detection`}</h1>
    <h2 {...{
      "id": "how-do-you-disable-motion-detection-when-you-are-away-from-home",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-do-you-disable-motion-detection-when-you-are-away-from-home",
        "aria-label": "how do you disable motion detection when you are away from home permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do you disable motion detection when you are away from home?`}</h2>
    <h4 {...{
      "id": "add-a-task-motion-detection-disabled",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#add-a-task-motion-detection-disabled",
        "aria-label": "add a task motion detection disabled permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a task (motion detection disabled)`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e98f5db829d2ee2098e4cdf8006823b0/772e8/Tasker-(12).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAACR0lEQVQ4y+3UzW+bMBgHYP5/accdeq/UaJ3WRavUtUuOIXzYBpooIQYCDpivKFAMaRIC6QRpp6mttE7bsY98sF+/P783c6Y1H2vybKJac9uyTOsNDMPQdd2yLA6bNrr8MJL7NvHmljX/E9u2McaKojiOw+HZbDIeYWyapmkYBv6N0XpWObbpuo4x5mBLaImiCCEEAMoQSgAMBZEXRak9AtC0SZI0HA6PbRDCJjwYDDqdztnZWbfbhQhBWRpDWQPi5ZfPXz91NFkcQxnKEkJKr9c7PT09Pz+XJAkA8DgZtQCEKgQ9Sf04mJ7w05Oh3ix++nEwvZE1FQKIkKIoCKHHybsnZVlWVbUvy2Kzizf75WYf3zer2Wz2+Xa32z7HOY4jiuJsNhuNRoqiYIwf3ozbbreMsfV6ned5kiR5nv9F+GXp8Gbcy+Q/TX4Pv4ffw/87XNd1+Zr9k+O+rutXwsV6naYpY+zu7o4xVhRFnudZliVJkqZpkjRXq9WKMfbyM3goy92tpn7rdlUF9Xs/rq+ubq6/dy8uAkrzLEvTlBCi63qWZVxd14fDoWrtq6quqjRjhuvrNpnOHUHRBgANkSooWrBK/SRjRREGQZIkRVFwnucRQuI4jo7C0A8CmmR+yvyUhawIsyIu7k0aoikeW45hWo79iCOECIKwWCyWy2UUhVEUUUpDn4a+7xLiEiegNAr88a0mDvlbTeV5XhCEfr8/mUy4OI5d1/U879fwMAyp71PfX7juwnX9IPAoDaMobl6Pli1KaRRFPwGpjA5OqS1yTwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e98f5db829d2ee2098e4cdf8006823b0/f13a9/Tasker-(12).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e98f5db829d2ee2098e4cdf8006823b0/ba381/Tasker-(12).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e98f5db829d2ee2098e4cdf8006823b0/772e8/Tasker-(12).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e98f5db829d2ee2098e4cdf8006823b0/772e8/Tasker-(12).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Press the + at the bottom of the task tab.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bf795edeacf43b8248a17e85a2c51424/772e8/Tasker-(13).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "158.50000000000003%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAABl0lEQVQ4y+2TXWviQBSG8//7K/RyCypsW2iRRmIIpNHapNuQj0lmRjNOZqZGvTDRxEkWGgque7Xbq4LP1cuB5z0Xh6MAELnuq+c6pvlkGIZpmpqmWZaFEAIARCf4vh+GYRRFAIAgCKIoUl6ct9nPK+dp+DBUr69/qKra6/VUVT2TIYSj0Wg2m2GMPc+bTCYQQiXwfdd5/vXm6ro+nU4Nw9A0Tdd13/cBAOEJnue1y4MgaIOS53m+P+R5XhRFfsLuL07nbVCaL3CRL/I3kuu6/n85SRII4XK5JIQghCCElFKMcRzHq9XqrP1cLsuyKIqqqvb7ffunh8OhzWVZXk51kf9ZllLWdV1VVRuOn8jdrtlum+1WFsVRyuOfNE0jpVQWi0WSJEKI9APGGBeCE7K5u3vX9ffHx/X9Pcsyzhj/hDGGMaaUKuPxuNPpbDYbx3EGgwEIQ5FlLI4zy7KTxAZgrWmpEOyjN01TIcR8Pu92u7ZtKxhjhBDnnFJKCEkpZZwzQtb9Pr+54f3++vY2zbJWbqGUAgAIIb8BCBg7GezN1p0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bf795edeacf43b8248a17e85a2c51424/f13a9/Tasker-(13).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bf795edeacf43b8248a17e85a2c51424/ba381/Tasker-(13).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bf795edeacf43b8248a17e85a2c51424/772e8/Tasker-(13).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bf795edeacf43b8248a17e85a2c51424/772e8/Tasker-(13).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Press the + again to open the Action Category dialog.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3672ac8f163cbe455edea95e654ca7d6/772e8/Tasker-(14).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEr0lEQVQ4y41Ua0/bSBTNL+cP8IHVqvyF3dIPpYlDlGpVFQKNUuyAgTzAiWOCjWPH73n7OSt7AtlqpdUeWdHM1Zzcc+eeua3Dw8Ov/X6n3e40kN6+3VbardudPdrtdr/fPzw8bPV6Pc45y4uq4mXF86oqqiqvKs55xXlaVmVVL+rt26IoCs55r9drddtfXlDeXvr953D0Gqmb+NKM6s+KHtxYtuO/1uG3dfj9JRyYkeYlBME4Tsqy/Pz5c6t3dlZUfBuDAMAAIIBwCHEIcABQAlHYBN0EWRHyAAoAJAhBAOrMZ2etP9tdC+d6AEzI1jFZ+mAVAD2AegDWCTUB0wO4CoBR/8J1QtdNxETZH1+kltTtcs5te3Mty7q+wpgAAAGEGJPZfD6ZTOsIRAAihPDj05OiKM/P6zTNTj59anUbchiG8/l8s9kQQmADxpimafP5nFIqIpRSwzDu7+89z2OMfRLkqqoIIZPJJIqi96Npmtq2Lc69/53v+7quZ1lGCDk5OdmRoyjyPC8MQ4wxhLXEKIrCBgAAQcYYi2MQwh1ZkqSmZns0GqmqSikFAFBKdV2/urq6uLhYLBYiyBizbVtRlCiKGGN7MoRQURTHcYRshBAhxLbtzWaDMUYIiUKWy+VwOMQY7zOXZUkIMU1TaHsXKQSLKwQApGnqOM5sNsvznBDy8ePHXWbTNJfL5Wq1EkkwxkmSrFYrwzCSJMENXNd9eXkxDGOxWCCE6sydTodz/vr6Ksvy3d2dyIMaqLe3d6oKAKhbjNB2u51Op+PxeD6f78iSJFVVRSm1LEuUCgkBlBaczzXtcbEoOQeUQkJSxpIk8TwvyzJK6U52WZZRFCVJApIEpinSddJuk/PzuNcDX7+S79+JJCHDAJSGQSDqQgjVZCH74eHh6upqLMuwKKii0MtLBsD95eX9YMAgJJeXVFHiLBvL8nA4fHp6yrJsf2EAgLu7u83rK+Gc3N4STaOc21HkJAnlnDw+kpsbwvnWcSzLElbdy6aUapoW+j7hHN/c0NPTdDqFo1H840c6nZLTU3x7SznHCDmOk6bprlVCtmEYlmVZpll3NgzJZBLK8vO3b9ZgEN/c4MkEx/HW9zVNMwzDNM1fWrVareqax+OdP6rKx3g8m41nM7/ZYkpd15Vl+fr6WlEUjPG+VWmanp+fe56XpmnNT5KMMXO9ti0rpRQmCWwchhAyDCPLMsbYTnZRFBjjoGmDcKUwRhAEURQhhEREdGjnGYz3tz0ejxVFub6+9n1fmIwQoqqqoiji9M5zqjoYDDRN+6VVjuNMJhPRBmEDxth6vTYMgxAiIoQQ13VVVfV9fyf7veblcik473Njs9lst9t/RoIg0DQtz/O97CzLTNP0PM80Td/3xdE4jt0GcRwLOZ7n2bbtND5hjJ2enrbOzs4YY6MGFxcXuq5TShFCYRjKsvzz508xm8T0E1czHA4ZY91udzc98zwviiLPc8EUzyttQAgRw4RSmqapeFKcc0mSatlxHOu6btt2/bDexp2YHv/eAgDiOC7LstPptPr9Pufcdd0wDIuiKP8H8jznnPf7/dbBwcHx8fHvDY7e8Nt/4ujo6MOHDwcHB38DWNucC0vMlcYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3672ac8f163cbe455edea95e654ca7d6/f13a9/Tasker-(14).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3672ac8f163cbe455edea95e654ca7d6/ba381/Tasker-(14).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3672ac8f163cbe455edea95e654ca7d6/772e8/Tasker-(14).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3672ac8f163cbe455edea95e654ca7d6/772e8/Tasker-(14).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Select the Net tile in the Category dialog.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2d51e117e147fd1f7c689e79587cb3ad/772e8/Tasker-(15).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFHElEQVQ4y41U2XLbyhXkF9sPtGz5K+gHfUT04lxxc1SpujdRyootibvAFQSIhQtAALOdWQCDmBQI2fJjuuZhpqsa0+fMadQuLy+vrq4ajUbj06fGpxKNn+vTb5vf0Wg0rq6uLi8va91uV2ud5yf9fyPPc631ly9faq2bPzzIbu3oXy7q7dDTDv1zk4wOuL/D//HQf/2SQYQy+gqMcZ7nnz9/rv2j20lPhXkkmwR2GGIKm4QdCAQEfAxbXJJScCm4+rkEMK11t9OpXTc7B3lyMfhUuEQ4CHzEXMRczD0i3JLkdsLKFTM7pnbC1ke8l/nfbtq1P1ptmeWRt022+2S7S2IUUxZTSIJjstsjf5uEUUwhJixhHHORUEgYqJP+e7NV63TaWmuxWGTfvqWDgUQJ58ClULadPT5mT0/Kc/nZtrlajUejqWE8PT4CY91ut9ZutwutWRyDZfHdjisFjAHnHCFwHHAcjjFwDqxsWRTHYRhGUZTneavVOouLglB6iCLCGIcSjFIQIkIowpgLwc4QQkRRNJ1OAaAoilLcbDaLopBSZkqlSkkpCSGc8zRN0zOjpOScM8bSM7Is45xrrZvNZq3T6TDGBoPBYrEwDGOz2WRZ5jjOaDRaLBaz2Ww+n/u+TwiZTCar1WqxWEwmkyAIOp1OaTtNU8uyfN93HCcMQyHE8XCwTdN33a3r7jwvCgJGqeM4nuf5vu+6LmOstN1qtbTWSqnVakUpTZViQkBR5Fp7UeTH8Q+toShAqSxNCSHr9VpK+WK71WqdTicAiOMYGONpylyXd7vZ3R27vaW3t9ndneh2wXFEllFCkiThnBdFcXNzU9qWUk4mE8Mwnsdja79PBwPx558H09yNRsHzc7Bey7/+Et+/7zHebUt4nqeUerU9nU7v7u6+3d8jpeRoxMfj5XZrrtdr255YljAM0e+bvr82TcuyhsMhQqhsWCUGAMuywiBItRa9npjPU61jgJDSklkuZa9XbgB2u51S6rXmPM8ppbZtY4TYjx98OFTX1/nj46HZPLbbPx4e1PU17/chz49BYFkWpfR0Or2Ii6KglMrzMHDOBcBhMjG/ft0OBuFk4nz/Hs3n/DxknHMpJaW0KIpS3G63Hce5v7/v9/uj0ajf7xNKjwj1xuN/399/fXjojceHKJJKxVH09PQ0Ho97vd7rzafTCSEUhiHGmBBCKVVKcc5N01ytVowxpVQ124SQKIowxq81F0UBALPZLEkSIQQAVHrXdX3fT9OUUgoAVTDm83kVjFLcbDa11lJKpVSWZdUlFc7ReD0KIZRSaZpKKV/EVTAeHh5M0xyNRqZplpFkTEr5/Pw8nU6llJVyv98PBoP5fG4YxkvN1YSt1+s4jm3b9jyPMVaZ3O/3QRBUeeScJ0ni+34YhpZlvdz8KxiL+ZxSmmXZL8+u63qel6ZpxSilCCGr1ep1SJrNZl4UhPMIIcI547xqT5ZllNJfra5IACCEAECe52UwWu22Vgp8Xx6PYr/n59AghDabTRAE5yBsMcb8/FH3jDAMfz5Vp6Mp5YuF8jxpGMK2+flJ+v2+7/uz2Ww4HIZhKKVECBmGMRwOl8vli7jdLn+9oBSiFNJUpikAVImNoihJkqIoqsdXSgkhwjCsai4jeXNzk2UZSpLgcEBJQnAJhBBjzDmDMYYQwhhTSsMwXC6XGOMsy8qa37x58/79+3q9fnFx8e431Ov1D2fU6/Vf5MXFxcePH9+9e/fhw4e3b9/+Dy5+kObV2sOwAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2d51e117e147fd1f7c689e79587cb3ad/f13a9/Tasker-(15).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2d51e117e147fd1f7c689e79587cb3ad/ba381/Tasker-(15).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2d51e117e147fd1f7c689e79587cb3ad/772e8/Tasker-(15).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2d51e117e147fd1f7c689e79587cb3ad/772e8/Tasker-(15).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Then select the HTTP GET action.`}</li>
    </ol>
    <p>{`Now we have to define the CGI command that is sent to the camera every time your smart phone leaves the Wi-Fi and connects to the cellular network. Enter the local IP of your camera as the server address - do not forget to attach the web port of your camera if it does not correspond to 80. If the address of your camera is e.g. 192.168.178.200 and you have changed the local port to 8081 (in the web interface of the camera under Network / IP Configuration - and not the external port you may have passed in your router), you have 192.168.178.200:8081 as a server Specify address. After that, only the matching CGI command in the Path field is missing.`}</p>
    <p>{`The CGI command to disable all 4 motion detection areas of your INSTAR HD camera is:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`/web/param.cgi?cmd=setmdattr&-name=1&-enable=0&cmd=setmdattr&-name=2&-enable=0&cmd=setmdattr&-name=3&-enable=0&cmd=setmdattr&-name=4&-enable=0?usr=admin&pwd=instar`}</code></pre></div>
    <p>{`Please change the username (usr) admin and password (pwd) instar according to your camera login.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bb51ca549b17005cc563463d8453f0c1/772e8/Tasker-(6).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "159%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAADiklEQVQ4y42UfW+iShTG+f7J/Rz9s3Xb3txtG2xsKtYi9IVV6mBRrICKKMwML/MCezso63V7u/3lhEzCnMzMec5zJACsH8/awNBv20pb0Ol0Li8vVVUdj8eWZY0Etm2rqjoYDGzbBgCYpjkajaSHpx/62V8PyvfWTfv8/KzRaMiyfHZ21uv16uTX11cAwLdv366vryeTiWmamqbZti0BAPrPTy8vw8FgoOv6w8ODqqqapo3HYwCAtYemaYZhjEYjAMBwOLQsS0IIYYwhhLEAYxxF0Wq1Wi6Xqx1hGK7Xa4RQtS1JkjRNsyyTZrOZLMs9QafTkWXZNM3NZhOG4UYQRVEcx0iQJAljbLFYNBqNJEmk+Xyu63pfYFmWruuO44RhGARBKFiv15vNJo5jCCFCiHNu2/b9/X1ZllJRFOWOas05L4qCUkoEdAcTVH+r/VJZlgnGnudihBbzebhaYYzd2SzPsvJ/KIqi4HybPHlzv19ePfcH/1xetW7bxsA8Pf87jGJWljnjhBd10F1kjLOikAgvFglZZtTHWZCxRUqqxTKlCxHLDyPnOSukPM8TjBCMEYzjKIqjCMbbL4xjGMcIQoxQgnGaJHmWUpIzSjGCZVlIjuMcHx9fXFx4vr8Kw2C1qmNbbSGYqPZ7T1DKHGd6dHSEMZbiOK5ad7lcBoJVnSZEigSVThhjxthsNhsOh4dSVXDO2W/wHYXgl1QQQtd1Pc8LgsA0TQhhrfknam2TPc/rCizL6vV6k8mEc04ISdMUC5IkIYRgjDNBnueMsW3ydDrtdrs3Nze6rnueZxhGt9t9eXlptVqdTufu7k6W5VarpShKv99XFOX29tb3/e2bD9qwevP+C6tWFX3F64pwziWyI89zQkiWZXBH5aTq2qkgyzJCCGOMUvp+suu6siw3m01FUZrNpqZp+zaujVm5EmNMKfV9/+Tk5N2SYRg+PT0ZhvH4+Kiqar/fD3esBZXU+zpPJhNN0z7QuS5Bzb4l60L80pkQghCqX/W5woc6R1EEAHh7e3McZz6fF1+gOv/92gdtSP9EPU+kLMuqOydJUo3Ommqe1qWqBMvznFIKIXxPnk6nV4LT09N2ux2G4YFUBwOQMeY4ztaSURSNRiPLsgAAtm0f6LRerz+z5O9l/NCJ+936H6nSNA2CoDoBIfRHqer8rZ9t2/Z933XdIAj419hKxcUQrmFf49/knxdEAIUiDtGlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bb51ca549b17005cc563463d8453f0c1/f13a9/Tasker-(6).avif 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bb51ca549b17005cc563463d8453f0c1/ba381/Tasker-(6).webp 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bb51ca549b17005cc563463d8453f0c1/772e8/Tasker-(6).png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bb51ca549b17005cc563463d8453f0c1/772e8/Tasker-(6).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Please change the username (usr) admin and password (pwd) instar according to your camera login.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      